import { type Router, type RouteLocationNormalized, type RouteLocationRaw } from 'vue-router'

type MiddlewareFunction = (router: Router, route: RouteLocationNormalized, next: () => void) => void
type MiddlewareList = MiddlewareFunction[]

function handleMiddleware(middlewareList: MiddlewareList, index: number, router: Router, route: RouteLocationNormalized, next: (Location?: RouteLocationRaw) => void) {
  if (index >= middlewareList.length) {
    next()
    return
  }

  const middleware = middlewareList[index]
  middleware(router, route, (location?: RouteLocationRaw) => {
    if (location) {
      next(location)
      return
    } else {
      handleMiddleware(middlewareList, index + 1, router, route, next)
    }
  })
}

export const useMiddleware = (router: Router) => {
  router.beforeEach((to, from, next) => {
    const middlewareList: MiddlewareList = []
    to.matched.forEach((record) => {
      const middleware = record.meta.middleware
      if (middleware && Array.isArray(middleware)) {
        middleware.forEach((item) => {
          middlewareList.push(item)
        })
      }
    })

    if (middlewareList.length === 0) {
      next()
      return
    } else {
      handleMiddleware(middlewareList, 0, router, to, next)
    }
  })
}
