import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'login',
    name: 'login',
    component: () => import('./Login.vue'),
    meta: { header: { showRegister: true, hideButtons: true } }
  },
  {
    path: 'logout',
    name: 'logout',
    component: () => import('./Logout.vue'),
  },
  {
    path: 'otp-login',
    name: 'otp-login',
    component: () => import('./OtpLogin.vue'),
    meta: { header: { showRegister: true, hideButtons: true } }
  },
]

export default routes
