<template>
  <button
    class="
      text-white px-1.5 py-2 text-sm min-w-[148px] font-bold
    "
    :class="
      {
        'min-w-[106px]': props.size === ButtonSize.SMALL,
        'rounded-full': !props.rectangular,
        'rounded': props.rectangular,
        'border border-solid': props.outline,
        '!bg-transparent': props.outline || props.light,
        'cursor-not-allowed': props.disabled,
        'bg-blue-500 border-blue-500 hover:bg-blue-600 hover:border-blue-600 disabled:bg-blue-100 disabled:hover:bg-blue-100': props.variant === ButtonVariant.PRIMARY,
        'bg-pink-500 border-pink-500 hover:bg-pink-600 hover:border-pink-600 disabled:bg-pink-100 disabled:hover:bg-pink-100': props.variant === ButtonVariant.ATTENTION,
        'text-blue-500 hover:text-blue-600 disabled:text-blue-100 disabled:hover:text-blue-100': (props.outline || props.light) && props.variant === ButtonVariant.PRIMARY,
        'text-pink-500 hover:text-pink-600 disabled:text-pink-100 disabled:hover:text-pink-100': (props.light || props.outline) && props.variant === ButtonVariant.ATTENTION,
      }
    "
    :disabled="props.disabled"
  >
    <slot />
  </button>
</template>

<script lang="ts" setup>
  import { ButtonSize, ButtonVariant } from "./types"

  export interface Props {
    light?: boolean
    outline?: boolean
    rectangular?: boolean
    size?: ButtonSize
    variant?: ButtonVariant
    disabled?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    light: false,
    rectangular: false,
    outline: false,
    size: ButtonSize.DEFAULT,
    variant: ButtonVariant.PRIMARY,
    disabled: false,
  })
</script>
