import { RouteRecordRaw } from 'vue-router'
import PetOwner from './pet-owner/routes'
import Profile from './profile/routes'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'analytics',
    name: 'expert-analytics',
    component: () => import('./Analytics.vue'),
  },
  {
    path: 'petowner',
    children: PetOwner,
  },
  {
    path: 'profile',
    children: Profile,
  },
]

export default routes
