import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'find-profile',
    name: 'onboarding-expert-find-profile',
    component: () => import('./FindProfile.vue'),
  },
  {
    path: 'profile',
    name: 'onboarding-expert-profile',
    component: () => import('./Profile.vue'),
  },
]

export default routes
