<template>
  <div v-if="socialMediaLinks.length" class="text-white">
    <div>{{ t('side_menu.follow_us') }}</div>
    <div class="mt-3 flex flex-row gap-4">
      <a v-for="socialMediaLink of socialMediaLinks" :key="socialMediaLink.type" :href="socialMediaLink.url" target="_blank">
        <component :is="socialMediaLink.icon" class="w-7 h-7 text-white" />
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Config from '@/utils/config'
import { useUserStore } from '@/stores/UserStore'
import { useI18n } from 'vue-i18n'
import { getSocialIcon } from '@/utils/social-icon'

const { t } = useI18n()
const userStore = useUserStore()

const socialMediaLinks = computed(() => {
  return (Config.socialMedia ?? [])
    .filter((socialMedia) =>
      (!socialMedia.country || socialMedia.country === userStore.country)
      &&
      (!socialMedia.language || socialMedia.language === userStore.language)
    ).map((socialMedia) => {
      return {
        type: socialMedia.type,
        url: socialMedia.url,
        icon: getSocialIcon(socialMedia.type),
      }
    })
})
</script>