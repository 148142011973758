import Admin from '@/views/admin/routes'
import Auth from '@/views/auth/routes'
import Common from '@/views/common/routes'
import Config from '@/utils/config'
import Expert from '@/views/expert/routes'
import Home from '@/views/home/routes'
import PetOwner from '@/views/pet-owner/routes'
import Onboarding from '@/views/onboarding/routes'
import { createMemoryHistory, createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { expertOnboarding } from '@/routes/middleware/ExpertOnboarding'
import { userInfo } from '@/routes/middleware/UserInfo'
import { nextTick } from 'vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:locale(.{2}-.{2})?',
    meta: { middleware: [userInfo()] },
    children: [
      {
        path: '',
        children: Home,
      },
      {
        path: '',
        children: Common,
        meta: { middleware: [expertOnboarding()] },
      },
      {
        path: 'admin',
        name: 'admin',
        children: Admin,
      },
      {
        path: 'auth',
        children: Auth,
      },
      {
        path: 'expert',
        children: Expert,
        meta: { middleware: [expertOnboarding()] },
      },
      {
        path: 'onboarding',
        children: Onboarding,
      },
      {
        path: 'petowner',
        children: PetOwner,
      },
      {
        path: ':pathMatch(.*)*',
        component: () => import('@/views/common/PageNotFound.vue'),
      },
    ],
  },
]

const base = Config.appBasePath
  ? `/${Config.appBasePath}/`
  : undefined

const index = createRouter({
  history: Config.ssr
    ? createMemoryHistory(base)
    : createWebHistory(base),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    }

    if (to.hash) {
      return { el: to.hash }
    }

    return { left: 0, top: 0 }
  },
})

export default index
