import ApiResponse from '@/models/responses/ApiResponse'
import ApiService from '@/services/ApiService'
import { useCacheStore } from '@/stores/CacheStore'
import { subDays } from 'date-fns'
import Config from '@/utils/config'

export default class FeatureService {
  private static url = 'main/features'

  public static async loadFeatures(): Promise<string[]> {
    const cacheStore = useCacheStore()
    if (
      cacheStore.features.length
      && cacheStore.getFeaturesCacheTime()
      && cacheStore.getFeaturesCacheTime()! > subDays(new Date(), Config.cacheDays)
    ) {
      return Promise.resolve(cacheStore.features)
    }

    return ApiService.get<ApiResponse<string[]>>(`${this.url}`).then((response) => {
      cacheStore.features = response.data
      cacheStore.setFeaturesCacheTime(new Date())
      return response.data
    })
  }
}