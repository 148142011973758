import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import User from '@/models/user/User'
import Connection, { ConnectionStatus } from '@/models/user/Connection'
import Expert from '@/models/expert/Expert'
import PetOwner from '@/models/pet-owner/PetOwner'
import Config from '@/utils/config'
import UserProduct from '@/models/product/UserProduct'
import OnboardingPet from '@/models/pet/OnboardingPet'
import { ProductFrequency } from '@/models/product/Product'

export const useUserStore = defineStore('user', () => {
  // state
  const connections = ref<Connection[]|undefined>(undefined)
  const cookieAcceptTime = ref<number|undefined>(undefined)
  const country = ref<string|undefined>(undefined)
  const deviceId = ref('')
  const experts = ref<Expert[]|undefined>(undefined)
  const language = ref<string>('de')
  const onboardingPets = ref<OnboardingPet[]|undefined>(undefined)
  const petOwners = ref<PetOwner[]|undefined>(undefined)
  const products = ref<string[]>([])
  const pwaPopoverShown = ref(false)
  const redirectLink = ref<string|undefined>(undefined)
  const user = ref<User|undefined>(undefined)
  const userProducts = ref<UserProduct[]|undefined>(undefined)
  const token = ref<string | undefined>(undefined)

  // getters
  const expert = (): Expert | undefined => {
    // TODO: implement expert switching and keep track of the current expert
    return experts.value?.length ? experts.value?.[0] as Expert : undefined
  }
  const getCookieAcceptTime = () => cookieAcceptTime.value ? new Date(cookieAcceptTime.value) : undefined
  const isLoggedIn = () => !!token.value
  const isSubscribed = (productKey: string, frequency: ProductFrequency) => {
    const key = `${productKey}_${frequency}`
    return products.value.includes(key)
  }
  const userFullName = (): string => {
    return `${user.value?.firstname ?? ''} ${user.value?.lastname ?? ''}`.trim()
  }
  const petOwner = (): PetOwner | undefined => {
    return petOwners.value?.length ? petOwners.value?.[0] : undefined
  }
  const userImageUrl = (): string => {
    if (user.value?.role === 'petowner') {
      return petOwner()?.image_url ?? Config.NO_IMAGE_URL
    } else if (user.value?.role === 'expert') {
      return experts.value?.[0]?.image_url ? experts.value[0].image_url : Config.NO_IMAGE_URL
    }
    return Config.NO_IMAGE_URL
  }

  // setters
  const setCookieAcceptTime = (time: Date|undefined) => {
    cookieAcceptTime.value = time?.getTime()
  }

  // actions
  const addConnection = (connection: Connection) => {
    if (!connections.value) return
    connections.value = connections.value
      .filter((c) => c.id !== connection.id)
      .concat(connection)
  }

  const addSubscription = (productKey: string, frequency: ProductFrequency) => {
    const key = `${productKey}_${frequency}`
    const hasProduct = products.value.includes(key)
    if (hasProduct) return
    products.value.push(key)
  }

  const expertUpdated = (expert: Expert) => {
    if (!experts.value) return
    experts.value = experts.value
      .filter((e) => e.id !== expert.id)
      .concat(expert)
  }

  const increaseUnreadActionCount = () => {
    if (!user.value) return
    user.value.unread_action_count ++
  }

  const increaseUnreadMessageCount = () => {
    if (!user.value) return
    user.value.unread_message_count++
  }

  const logout = () => {
    connections.value = undefined
    experts.value = undefined
    petOwners.value = undefined
    redirectLink.value = undefined
    user.value = undefined
    userProducts.value = undefined
    token.value = undefined
  }

  const petOwnerUpdated = (petOwner: PetOwner) => {
    if (!petOwners.value) return
    petOwners.value = petOwners.value
      .filter((p) => p.id !== petOwner.id)
      .concat(petOwner)
  }

  const removeSubscription = (productKey: string, frequency: ProductFrequency) => {
    const key = `${productKey}_${frequency}`
    const index = products.value.indexOf(key)
    if (index === -1) return
    products.value.splice(index, 1)
  }

  const updateConnectionStatus = (connectionId: string, status: ConnectionStatus, invited_by: number) => {
    if (!connections.value) return
    const connection = connections.value.find((c) => c.id === connectionId)
    if (!connection) return
    connection.status = status
    connection.invited_by = invited_by
  }

  watch(userProducts, (newValue) => {
    products.value = newValue?.map((p) => `${p.product?.key}_${p.product?.frequency}`) ?? []
  }, { immediate: true })

  return {
    connections,
    cookieAcceptTime,
    country,
    deviceId,
    experts,
    language,
    onboardingPets,
    petOwners,
    pwaPopoverShown,
    redirectLink,
    user,
    userProducts,
    token,

    expert,
    getCookieAcceptTime,
    isLoggedIn,
    isSubscribed,
    petOwner,
    userFullName,
    userImageUrl,

    addConnection,
    addSubscription,
    expertUpdated,
    increaseUnreadActionCount,
    increaseUnreadMessageCount,
    logout,
    petOwnerUpdated,
    removeSubscription,
    setCookieAcceptTime,
    updateConnectionStatus
  }
}, { persist: !import.meta.env.SSR })
