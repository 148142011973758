import { EventBus } from '@/services/event-bus'

export type ConfirmPopupOptions = {
  inputs: { label: string, name: string }[]
  messageKey: string
  messageParams?: Record<string, string>
  noButtonKey?: string
  yesButtonKey?: string
}

export const showConfirmPopup = (messageKey: string, yesButtonKey?: string, noButtonKey?: string, messageParams?: Record<string, string>): Promise<boolean> => {
  return showInputPopup(messageKey, [], yesButtonKey, noButtonKey, messageParams).then((result) => result.result)
}

export const showInputPopup = (
  messageKey: string,
  inputs: { name: string, label: string }[],
  yesButtonKey?: string,
  noButtonKey?: string,
  messageParams?: Record<string, string>
): Promise<{ result: boolean, inputs: Record<string, string> }> => {
  const options = {
    messageKey,
    messageParams,
    yesButtonKey,
    noButtonKey,
    inputs,
  }
  return new Promise<{ result: boolean, inputs: Record<string, string> }>((resolve) => {
    const handler = (response: { result: boolean, inputs: Record<string, string> }) => {
      EventBus.unsubscribe('confirmPopupCallback', handler)
      resolve(response)
    }

    EventBus.subscribe('confirmPopupCallback', handler)
    EventBus.dispatch('showConfirmPopup', options)
  })
}