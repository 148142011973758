import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'admin.pet-breed.list',
    component: () => import('./List.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'create',
    name: 'admin.pet-breed.create',
    component: () => import('./Edit.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: ':id',
    name: 'admin.pet-breed.edit',
    component: () => import('./Edit.vue'),
    meta: { header: { showBackButton: true } }
  },
]

export default routes
