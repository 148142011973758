import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'admin.product.list',
    component: () => import('./List.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'create',
    name: 'admin.product.create',
    component: () => import('./Edit.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: ':id',
    name: 'admin.product.edit',
    component: () => import('./Edit.vue'),
    meta: { header: { showBackButton: true } }
  },
]

export default routes
