<template>
  <div
    v-if="!options.hideHeaderOnMobile || breakpoints.greaterOrEqual('md').value"
    class="w-screen z-[10000] m-0"
    :style="{ height: headerHeight + 'px' }"
  >
    <HeaderBackground
      v-if="options.blueTheme && breakpoints.smaller('md').value"
      class="fixed z-[800000] bottom-[calc(100vh-184px)] left-1/2 w-screen min-w-[375px] min-h-[184px] transform translate-x-[-50%] pointer-events-none"
    />
    <HeaderBackgroundDesktop
      v-if="options.blueTheme && breakpoints.greaterOrEqual('md').value"
      class="fixed z-[800000] bottom-[calc(100vh-509px)] xl:top-0 xl:bottom-auto left-1/2 w-screen min-w-[1440px] min-h-[509px] transform translate-x-[-50%] pointer-events-none"
    />
    <header
      class="fixed top-0 w-full z-[800100]"
      :class="{
        'h-auto': !options.blueTheme,
        'bg-white': !options.blueTheme,
      }"
      :style="{ height: headerHeight + 'px' }"
    >
      <div class="flex flex-row h-[56px] md:h-[84px] mx-4 md:mx-8 my-0 items-center">
        <div class="ml-1 grow-0">
          <RouterLink v-if="!options.showBackButton" :to="{ name: 'home' }">
            <Logo
              class="object-contain h-[24px] sm:h-[32px] md:h-[52px]"
              :blue-theme="options.blueTheme"
              :size="breakpoints.smaller('sm').value ? LogoSize.SMALL : (breakpoints.smaller('md').value ? LogoSize.MEDIUM : LogoSize.DEFAULT)"
            />
          </RouterLink>

          <BackButton v-if="options.showBackButton" :toUrl="options.backUrl" />
        </div>

        <h1 v-if="!options.blueTheme && options.title">{{ t(options.title) }}</h1>

        <div class="flex-grow max-w-[80px]" />
        <div class="center-panel flex grow shrink">
          <!-- TODO: search bar -->
          <!--          <Input-->
          <!--            v-if="options.showSearchBar && !options.blueTheme"-->
          <!--            class="relative min-w-[80px] max-w-[360px] h-[38px] border-blue-100 rounded-[4px] pl-[10px] md:pl-[30px]"-->
          <!--            style="width: available"-->
          <!--            v-model="searchText"-->
          <!--            :placeholder="t('header.search_placeholder')"-->
          <!--            @keyup.enter="searchClicked">-->
          <!--            <Icon v-if="breakpoints.greaterOrEqual('md').value" :type="search" size="20"  class="absolute left-[5px] top-[10px]" />-->
          <!--          </Input>-->
          <!--          <Button v-if="options.showSearchBar && !options.blueTheme" @click="searchClicked" class="m-1 p-1 btn-search hidden-mobile" light>{{ t('header.search') }}</Button>-->
          <!--          <SearchIcon v-if="showSearchBar && !options.blueTheme" @click="searchClicked" class="mb:hidden w-8 h-8 cursor-pointer" />-->
        </div>
        <div class="flex-grow max-w-[80px]" />

        <div class="end-buttons flex flex-row items-center grow-0 gap-2">
          <ShareButton
            v-if="!userStore.isLoggedIn() && !options.hideButtons && (!options.showSearchBar || breakpoints.greaterOrEqual('md').value)"
            viewTitle="share.intro_view_title"
            shareTitle="share.intro_share_title"
            shareUrl="/"
          />
          <div v-for="(menu, index) in menuItems" :key="index" class="relative">
            <button
              @click="openMenu(menu.to!)"
              class="m-1 p-1"
              :class="{ 'hidden md:block': options.showSearchBar }"
            >
              <VaBadge overlap :text="menu.badge" style="--va-badge-text-wrapper-border-radius: 50%;" :offset="[-4,4]">
                <img v-if="menu.icon === 'emergency'" class="w-8 h-8" src="/icons/emergency-animated.svg" />
                <component
                  v-if="menu.icon !== 'emergency'"
                  :is="options.blueTheme ? menuIconMapSky[menu.icon!] : menuIconMap[menu.icon!]"
                  class="w-8 h-8 object-contain"
                />
              </VaBadge>
            </button>
          </div>
          <RouterLink :to="{ name: 'login' }" v-if="showLoginButton" class="flex flex-row items-center gap-2 p-2 text-sm font-semibold tracking-wider">
            <LoginIcon v-if="!options.blueTheme" class="w-6 h-6" />
            <LoginIconWhite v-if="options.blueTheme" class="w-6 h-6" />
            <span>{{ t('common.log_in') }}</span>
          </RouterLink>
          <RouterLink :to="{ name: 'onboarding-select' }" v-if="showRegisterButton" class="flex flex-row items-center gap-2 p-2 text-sm font-semibold tracking-wider">
            <LoginIcon v-if="!options.blueTheme" class="w-6 h-6" />
            <LoginIconWhite v-if="options.blueTheme" class="w-6 h-6" />
            <span>{{ t('common.register') }}</span>
          </RouterLink>
          <div v-if="userStore.isLoggedIn() && userStore.user?.role !== 'admin'" class="hidden sm:flex flex-row items-center cursor-pointer mx-0 my-2.5 gap-1" @click="menuBtnTapped">
            <RoundImage class="w-12 h-12 mr-2.5 p-2" :imageUrl="userStore.userImageUrl()" :imageText="userStore.userFullName()" dashed />
            <span
              class="hidden md:block font-bold text-sm max-w-[180px] overflow-hidden whitespace-nowrap text-ellipsis"
              :class="{ 'text-white': options.blueTheme }"
            >
              {{ userStore.userFullName() }}
            </span>
          </div>
          <button class="p-2 pt-3" @click="menuBtnTapped">
            <MenuIcon v-if="!options.blueTheme" />
            <MenuIconAlpha v-if="options.blueTheme" />
          </button>
        </div>
      </div>
      <p v-if="options.blueTheme && options.title" class="text-center text-white text-xl font-bold mt-6 md:mt-0">{{ t(options.title) }}</p>
      <slot />
    </header>

    <SideMenu />
  </div>
</template>

<script lang="ts" setup>
import { VaBadge } from 'vuestic-ui'
import BackButton from '@/components/BackButton.vue'
import HeaderBackground from '@/assets/images/header_background.svg'
import HeaderBackgroundDesktop from '@/assets/images/header_background_desktop.svg'
import LoginIcon from '@/assets/icons/login.svg'
import LoginIconWhite from '@/assets/icons/login_white.svg'
import MenuIcon from '@/assets/icons/menu_icon.svg'
import MenuIconAlpha from '@/assets/icons/menu_icon_alpha.svg'
import MenuIconChat from '@/assets/icons/menu/chat.svg'
import MenuIconChatSky from '@/assets/icons/menu/chat_sky.svg'
import MenuIconInfo from '@/assets/icons/menu/info.svg'
import MenuIconInfoSky from '@/assets/icons/menu/info_sky.svg'
import MenuIconSearch from '@/assets/icons/menu/search.svg'
import MenuIconSearchSky from '@/assets/icons/menu/search_sky.svg'
import RoundImage from '@/components/RoundImage.vue'
import ShareButton from '@/components/ShareButton.vue'
import { LogoSize } from '@/components/ui'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import { computed, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router';
import { useUserStore } from '@/stores/UserStore';
import SideMenu from '@/components/side-menu/SideMenu.vue'
import { EventBus } from '@/services/event-bus'
import { IMenu } from '@/models/IMenu'
import { Logo } from '@/components/ui/logo/Logo'
import { useCacheStore } from '@/stores/CacheStore'

const { t } = useI18n()
const breakpoints = useBreakpoints(breakpointsTailwind)
const cacheStore = useCacheStore()
const menuIconMap: Record<string, string> = {
  chat: MenuIconChat,
  info: MenuIconInfo,
  search: MenuIconSearch,
}
const menuIconMapSky: Record<string, string> = {
  chat: MenuIconChatSky,
  info: MenuIconInfoSky,
  search: MenuIconSearchSky,
}
const route = useRoute()
const router = useRouter()
const searchText = ref('')
const userStore = useUserStore()

class HeaderOptions {
  blueTheme = false
  showBackButton = false
  backUrl = ''
  title = ''
  showSearchBar = false
  showLogin = false
  showRegister = false
  hideButtons = false
  hideHeaderOnMobile = false
}

const options = reactive<HeaderOptions>(new HeaderOptions())

const emit = defineEmits<{
  (event: 'doSearch', searchText: string): void
}>()

const menuBtnTapped = (): Promise<void> => {
  EventBus.dispatch('openSideMenu')
  return Promise.resolve()
}

const menuItems = computed(() => {
  if (options.showSearchBar || breakpoints.smaller('md').value) {
    return []
  }

  const items: IMenu[] = []
  if (userStore.isLoggedIn()) {
    items.push(...[
      { to: { name: 'emergency' }, icon: 'emergency' },
      {
        to: { name: 'action-center' },
        icon: 'info',
        badge: userStore.user?.unread_action_count ? userStore.user.unread_action_count.toString() : undefined
      },
      {
        to: { name: 'message-center' },
        icon: 'chat',
        badge: userStore.user?.unread_message_count ? userStore.user.unread_message_count.toString() : undefined
      },
      { to: { name: 'search' }, icon: 'search' },
    ])
  } else {
    items.push(...[
      { to: { name: 'emergency' }, icon: 'emergency' },
      { to: { name: 'search' }, icon: 'search' },
    ])
  }

  return items.filter(item => {
    if (options.hideButtons) return false
    if (!item.feature) return true
    return cacheStore.isFeatureActive(item.feature)
  })
})

const searchClicked = () => {
  emit('doSearch', searchText.value)
}

const headerHeight = computed((): number => {
  if (breakpoints.greaterOrEqual('md').value) {
    return options.blueTheme ? 160 : 84
  } else {
    return options.blueTheme ? 185 : 56
  }
})

const openMenu = (to: RouteLocationRaw): void => {
  router.push(to)
}

const showLoginButton = computed((): boolean => {
  return options.showLogin && !userStore.isLoggedIn()
})

const showRegisterButton = computed((): boolean => {
  return options.showRegister && !userStore.isLoggedIn()
})

const headerOptions = computed(() => {
  return route.matched.slice().reverse().find((record) => {
    return record.meta.header
  })?.meta.header
})

watch(() => route.fullPath, () => {
  searchText.value = ''
})

watch(headerOptions, (value) => {
  const newOptions = new HeaderOptions()
  if (value) {
    Object.assign(newOptions, value)
  }
  options.blueTheme = newOptions.blueTheme
  options.showBackButton = newOptions.showBackButton
  options.backUrl = newOptions.backUrl
  options.title = newOptions.title
  options.showSearchBar = newOptions.showSearchBar
  options.showLogin = newOptions.showLogin
  options.showRegister = newOptions.showRegister
  options.hideButtons = newOptions.hideButtons
  options.hideHeaderOnMobile = newOptions.hideHeaderOnMobile
}, { immediate: true })
</script>