// Do not insert custom values to the colors or breakpoints Vuestic UI config
// or they will be overwritten! Use Tailwind CSS config instead.
import { createIconsConfig } from 'vuestic-ui'

const iconsConfig = createIconsConfig({
	aliases: [
		{ name: 'va-arrow-down', to: 'expand_more' },
		{ name: 'va-arrow-up', to: 'expand_less' },
		{ name: 'va-calendar', to: 'calendar_today' },
		{ name: 'va-check', to: 'check' },
		{ name: 'va-clear', to: 'cancel' },
		{ name: 'va-close', to: 'close' },
		{ name: 'va-loading', to: 'sync' },
    { name: 'va-warning', to: 'warning' },
	],
	fonts: [
		{
			name: '{icon}',
			resolve: ({ icon }) => ({ class: `material-symbols-outlined`, content: icon }),
		},
	],
})

export default {
	breakpoints: {
		thresholds: {
			xs: 320,
			sm: 480,
			md: 800,
			lg: 1024,
		},
	},
	colors: {
		variables: {
			black: '#000',
			white: '#fff',
			primary: '#0F67AE',
			success: '#669F8C',
			danger :'#EF556F',
			backgroundPrimary: '#ffffff',
			textPrimary: '#003056',

			'blue-100': '#EFF5FF',
			'blue-150': '#bfd6e8',
			'blue-200': '#9AB6DD',
			'blue-300': '#81C2FF',
			'blue-400': '#3B94DB',
			'blue-500': '#0F67AE',
			'blue-550': '#255ca5',
			'blue-600': '#102F53',
			'blue-700': '#5D83A1',
			'blue-800': '#0b2038',
			'pink-100': '#FFD5CE',
			'pink-500': '#EF556F',
			'pink-600': '#CD4159',
			'green-100': '#E6F9F2',
			'green-500': '#669F8C',
			'green-600': '#0fae58',
			'red-100': '#C62222',
			'yellow-100': '#E1B982',
			'gray-20': '#E9EBF0',
			'gray-70': '#33384F',
			'gray-100': '#C4C4C4',
		},
		threshold: 170,
	},
	components: {
		VaPopover: {
			color: 'blue-100',
		},
	},
	fontFamily: {
		sans: ['Comfortaa', 'sans-serif'],
	},
	fontSize: {
		'2xs': ['10px', { lineHeight: '12px'}],
		xs: ['12px', { lineHeight: '14px'}],
		sm: ['14px', { lineHeight: '18px'}],
		md: ['16px', { lineHeight: '18px'}],
		lg: ['18px', { lineHeight: '24px'}],
		xl: ['24px', { lineHeight: '30px'}],
		'2xl': ['28px', { lineHeight: '36px'}],
		'3xl': ['36px', { lineHeight: '44px'}],
	},
	icons: iconsConfig,
}
