import { defineComponent, h, readonly, ref , createSSRApp } from 'vue'
import type { MetaDataType } from '@/models/MetaData'
import { renderToString } from '@vue/server-renderer'
import { MetaTags } from '@/components/MetaTags'
import { useRoute } from 'vue-router'

let metaData = {
  title: ref<string | undefined>(undefined),
  description: ref<string | undefined>(undefined),
  image: ref<string | undefined>(undefined),
  url: ref<string | undefined>(undefined),
  noIndex: ref<boolean | undefined>(undefined),
  language: ref<string | undefined>(undefined),
  next: ref<string | undefined>(undefined),
  prev: ref<string | undefined>(undefined),
}

const metaDataFn = ref(() => metaData)

export const useMeta = () => {
  const route = useRoute()

  const renderMetaTags = () => {
    const App = defineComponent({
      setup() {
        return () => h(MetaTags, { metaDataFn: () => metaData, ssr: true })
      },
    })
    const app = createSSRApp(App)
    return renderToString(app)
  }

  const replaceTeleport = (
    currentTeleport: string | undefined,
    addTeleport: string
  ): string | undefined => {
    const startString = '<!--[--><!--meta-tags-start-->'
    const endString = '<!--meta-tags-end--><!--]-->'
    const anchorString = '<!--teleport anchor-->'
    if (!currentTeleport) {
      return addTeleport + anchorString
    }
    const startIndex = currentTeleport.indexOf(startString)
    const endIndex = currentTeleport.indexOf(endString)
    let newTeleport = ''
    if (startIndex === -1 || endIndex === -1) {
      newTeleport = addTeleport + currentTeleport
    } else {
      newTeleport =
        currentTeleport.slice(0, startIndex) +
        addTeleport +
        currentTeleport.slice(endIndex + endString.length)
    }
    if (!newTeleport.endsWith(anchorString)) {
      newTeleport += anchorString
    }
    return newTeleport
  }

  const setPrevNextLinks = (currentPage: number, lastPage: number, pageQueryParameter = 'page') => {
    if (currentPage == 1) {
      metaData.prev.value = undefined
    } else {
      const url = route.fullPath
      const urlObj = new URL(url, window.location.origin)
      urlObj.searchParams.set(pageQueryParameter, (currentPage - 1).toString())
      metaData.prev.value = urlObj.toString()
    }

    if (currentPage == lastPage) {
      metaData.next.value = undefined
    } else {
      const url = route.fullPath
      const urlObj = new URL(url, window.location.origin)
      urlObj.searchParams.set(pageQueryParameter, (currentPage + 1).toString())
      metaData.next.value = urlObj.toString()
    }
  }

  const updateMetaData = (newMetaData: MetaDataType) => {
    metaData = { ...metaData, ...newMetaData }
    metaDataFn.value = () => metaData
  }

  return {
    metaData: readonly(metaData),
    metaDataFn: readonly(metaDataFn),
    renderMetaTags,
    replaceTeleport,
    setPrevNextLinks,
    updateMetaData,
  }
}
