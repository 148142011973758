import '@/assets/main.css'
import { createApp as createVueApp, createSSRApp } from "vue"
import { createPinia } from "pinia"
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { i18n } from "./i18n"
import router from "./routes"
import App from "@/App.vue"
import VueGoogleMaps from "vue-google-maps-community-fork"
import VueGtag from 'vue-gtag'
import Config from "@/utils/config"
import { createVuesticEssential } from 'vuestic-ui';
// @ts-ignore
import vuesticConfig from '../vuestic.config.js'
import { useMiddleware } from '@/routes/middleware'
import { createRouterScroller } from 'vue-router-better-scroller'
import { initializeSentry } from '@/sentry'
import { initOneSignal } from '@/onesignal'

export const createApp = (request?: any) => {
  const app = import.meta.env.SSR ? createSSRApp(App) : createVueApp(App)

  initializeSentry(app, router)

  const pinia = createPinia()
  pinia.use(piniaPluginPersistedstate)
  app.use(pinia)
  app.use(router)
  useMiddleware(router)

  if (!import.meta.env.SSR) {
    app.use(createRouterScroller({
      selectors: {
        'main': true,
      },
    }), { history: router.options.history })
  }

  const acceptLanguage = request?.headers['accept-language']
  app.use(i18n(request?.url, acceptLanguage), {})
  app.use(createVuesticEssential({
    config: vuesticConfig,
  }))

  if (!import.meta.env.SSR) {
    app.use(VueGoogleMaps, {
      load: {
        key: Config.googleMapsApiKey,
        language: "en", // TODO: dynamic
      },
    })
  }

  if (Config.googleAnalyticsTag) {
    app.use(VueGtag, {
      config: { id: Config.googleAnalyticsTag },
    })
  }

  initOneSignal()

  return {
    app,
    router,
  }
}