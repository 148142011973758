import { defineAsyncComponent, onMounted, onUnmounted, ref } from 'vue'
import { EventBus } from '@/services/event-bus'

export function useSpinnerDisplay() {
  const SpinnerModal = defineAsyncComponent(() =>
    import('@/components/SpinnerModal.vue')
  )

  const showSpinner = ref(false)
  const spinnerMessage = ref<string|undefined>('')
  const spinnerMessageParams = ref<Record<string, string>|undefined>({})

  onMounted(() => {
    EventBus.subscribe('showSpinner', handleShowSpinner)
    EventBus.subscribe('hideSpinner', handleHideSpinner)
  })

  onUnmounted(() => {
    EventBus.unsubscribe('showSpinner', handleShowSpinner)
    EventBus.unsubscribe('hideSpinner', handleHideSpinner)
  })

  const handleHideSpinner = () => {
    showSpinner.value = false
  }

  const handleShowSpinner = (payload: { message?: string, messageParams?: Record<string, string> }) => {
    spinnerMessage.value = payload.message
    spinnerMessageParams.value = payload.messageParams
    showSpinner.value = true
  }

  return {
    SpinnerModal,
    showSpinner,
    spinnerMessage,
    spinnerMessageParams,
  }
}