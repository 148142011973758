<template>
  <VaModal
    v-model="showModal"
    size="small"
    close-button
    @close="callback(false)"
    :before-cancel="(hide) => { callback(false); hide() }"
    :before-ok="(hide) => { callback(true); hide() }"
    :okText="t(yesButtonKey)"
    :cancelText="t(noButtonKey)"
  >
    <div class="flex flex-col gap-4">
      <p>{{ t(messageKey, messageParams!) }}</p>
      <VaInput v-for="input in inputs" :key="input.name" :model-value="input.value" @update:model-value="input.value = $event" :label="t(input.label)" />
    </div>
  </VaModal>
</template>

<script lang="ts" setup>
import { VaInput, VaModal } from 'vuestic-ui'
import { useI18n } from 'vue-i18n'
import { onMounted, ref } from 'vue'
import { EventBus } from '@/services/event-bus'
import { ConfirmPopupOptions } from '@/utils/confirm-popup'

const { t } = useI18n()
const inputs = ref<{ label: string, name: string, value: string }[]>([])
const messageKey = ref('')
const messageParams = ref<Record<string, string> | undefined>(undefined)
const noButtonKey = ref('')
const yesButtonKey = ref('')
const showModal = ref(false)

onMounted(() => {
  EventBus.subscribe('showConfirmPopup', (options: Partial<ConfirmPopupOptions>) => {
    inputs.value = (options.inputs ?? []).map(input => ({ label: input.label, name: input.name, value: '' }))
    messageKey.value = options.messageKey ?? ''
    messageParams.value = options.messageParams
    noButtonKey.value = options.noButtonKey ?? 'common.no'
    yesButtonKey.value = options.yesButtonKey ?? 'common.yes'
    showModal.value = true
  })
})

const callback = (result: boolean) => {
  const inputValues = inputs.value.reduce((acc, input) => ({ ...acc, [input.name]: input.value }), {})
  EventBus.dispatch('confirmPopupCallback', { result, inputs: inputValues })
}
</script>