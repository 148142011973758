import SocketService from '@/services/SocketService'
import { onUnmounted, watch } from 'vue'
import { useUserStore } from '@/stores/UserStore'

export function useSockets() {
  const userStore = useUserStore()

  onUnmounted(() => {
    SocketService.disconnect()
  })

  watch(() => userStore.user?.id, (newUserId: string|undefined, oldUserId: string|undefined) => {
    if (oldUserId) {
      SocketService.stopListenUser(oldUserId)
    }
    if (!newUserId) return
    if (SocketService.getStatus() !== 'connected') {
      SocketService.connect()
    }
    SocketService.listenUser(newUserId)
  }, { immediate: true })

  return {}
}