import { RouteRecordRaw } from 'vue-router'
import Content from './content/routes'
import ExpertCategories from './expert-categories/routes'
import Experts from './experts/routes'
import Info from './info/routes'
import LinkQr from './link-qr/routes'
import Media from './media/routes'
import Payments from './payments/routes'
import PetBreeds from './pet-breeds/routes'
import PetOwners from './pet-owners/routes'
import PetSpecies from './pet-species/routes'
import Products from './products/routes'
import Salutations from './salutations/routes'
import Templates from './templates/routes'
import Users from './users/routes'

const routes: Array<RouteRecordRaw> = [
  // TODO: all dashboard lists: sortable columns
  {
    path: '',
    name: 'admin.dashboard',
    component: () => import('./Dashboard.vue'),
  },
  {
    path: 'content',
    children: Content,
  },
  {
    path: 'expert-categories',
    children: ExpertCategories,
  },
  {
    path: 'experts',
    children: Experts,
  },
  {
    path: 'info',
    children: Info,
  },
  {
    path: 'link-qr',
    children: LinkQr,
  },
  {
    path: 'media',
    children: Media,
  },
  {
    path: 'payments',
    children: Payments,
  },
  {
    path: 'pet-breeds',
    children: PetBreeds,
  },
  {
    path: 'pet-owners',
    children: PetOwners,
  },
  {
    path: 'pet-species',
    children: PetSpecies,
  },
  {
    path: 'products',
    children: Products,
  },
  {
    path: 'salutations',
    children: Salutations,
  },
  {
    path: 'templates',
    children: Templates,
  },
  {
    path: 'users',
    children: Users,
  },
]

export default routes
