import { onMounted, onUnmounted } from 'vue'
import { EventBus } from '@/services/event-bus'

export function useAudio() {
  let incomingAudio: HTMLAudioElement
  let outgoingAudio: HTMLAudioElement
  let disconnectAudio: HTMLAudioElement

  const getPlayStatus = (audioEl: HTMLAudioElement): boolean => {
    return (
      audioEl.currentTime > 0 && !audioEl.paused && audioEl.ended && audioEl.readyState > audioEl.HAVE_CURRENT_DATA
    )
  }

  const pauseIncomingAudio = () => {
    if (incomingAudio) {
      incomingAudio.pause()
    }
  }

  const pauseOutgoingAudio = () => {
    if (outgoingAudio) {
      outgoingAudio.pause()
    }
  }

  const playDisconnectAudio = () => {
    if (!disconnectAudio) {
      disconnectAudio = new Audio()
    }
    disconnectAudio.src = '/audio/disconnect.mp3'
    if (incomingAudio)
      incomingAudio.pause()
    if (outgoingAudio)
      outgoingAudio.pause()
    if (!getPlayStatus(disconnectAudio))
      disconnectAudio.play()
  }

  const playIncomingAudio = () => {
    if (!incomingAudio) {
      incomingAudio = new Audio()
    }
    incomingAudio.src = '/audio/incoming.mp3'
    incomingAudio.loop = true
    if (!getPlayStatus(incomingAudio))
      incomingAudio.play()
  }

  const playOutgoingAudio = () => {
    if (!outgoingAudio) {
      outgoingAudio = new Audio()
    }
    outgoingAudio.src = '/audio/outgoing.mp3'
    outgoingAudio.loop = true
    if (!getPlayStatus(outgoingAudio))
      outgoingAudio.play()
  }

  onMounted(() => {
    EventBus.subscribe('pauseIncomingAudio', pauseIncomingAudio)
    EventBus.subscribe('pauseOutgoingAudio', pauseOutgoingAudio)
    EventBus.subscribe('playDisconnectAudio', playDisconnectAudio)
    EventBus.subscribe('playIncomingAudio', playIncomingAudio)
    EventBus.subscribe('playOutgoingAudio', playOutgoingAudio)
  })

  onUnmounted(() => {
    EventBus.unsubscribe('pauseIncomingAudio', pauseIncomingAudio)
    EventBus.unsubscribe('pauseOutgoingAudio', pauseOutgoingAudio)
    EventBus.unsubscribe('playDisconnectAudio', playDisconnectAudio)
    EventBus.unsubscribe('playIncomingAudio', playIncomingAudio)
    EventBus.unsubscribe('playOutgoingAudio', playOutgoingAudio)
  })

  return {}
}