import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'expert-profile',
    component: () => import('./Profile.vue'),
  },
  {
    path: 'business-hours',
    name: 'expert-profile-business-hours',
    component: () => import('./BusinessHours.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'edit',
    name: 'expert-profile-edit',
    component: () => import('./ProfileEdit.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'edit-address',
    name: 'expert-profile-edit-address',
    component: () => import('./ProfileEditAddress.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'emergency-coverage',
    name: 'expert-profile-emergency-coverage',
    component: () => import('./EmergencyCoverage.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'emergency-edit',
    name: 'expert-profile-emergency',
    component: () => import('./EmergencyService.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'users',
    name: 'expert-profile-users',
    component: () => import('./Users.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'video-call',
    name: 'expert-profile-video-call',
    component: () => import('./VideoCall.vue'),
    meta: { header: { showBackButton: true } }
  },
]

export default routes
