import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'pet-owner-profile',
    component: () => import('./Profile.vue'),
  },
  {
    path: 'edit',
    name: 'pet-owner-profile-edit',
    component: () => import('./Edit.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'edit-address',
    name: 'pet-owner-profile-edit-address',
    component: () => import('./EditAddress.vue'),
    meta: { header: { showBackButton: true } }
  },
]

export default routes
