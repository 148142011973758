import { useUserStore } from '@/stores/UserStore'
import UserInfo from '@/models/user/UserInfo'
import ApiService from '@/services/ApiService'
import ApiResponse from '@/models/responses/ApiResponse'
import { EventBus } from '@/services/event-bus'
import UserRegisterRequest from '@/models/requests/UserRegisterRequest'
import InvestorRequest from '@/models/requests/InvestorRequest'
import ListRequest from '@/models/requests/ListRequest'
import PaginationModel from '@/models/responses/PaginationModel'
import User from '@/models/user/User'
import SaveUserRequest from '@/models/requests/SaveUserRequest'
import UserShortInfo from '@/models/user/UserShortInfo'
import PetOwner from '@/models/pet-owner/PetOwner'
import SendInviteRequest from '@/models/requests/SendInviteRequest'
import ContactFormRequest from '@/models/requests/ContactFormRequest'
import Transaction from '@/models/user/Transaction'
import { GetUserTransactionsRequest } from '@/models/requests/GetUserTransactionsRequest'
import QuickRegisterRequest from '@/models/requests/QuickRegisterRequest'

export default class UserService {
  private static url = 'user'

  public static async getUserInfo(): Promise<UserInfo|undefined> {
    const userStore = useUserStore()
    if (!userStore.isLoggedIn()) {
      return undefined
    }

    return ApiService.get<ApiResponse<UserInfo>>(`main`).then((response) => {
      userStore.user = response.data.user
      userStore.experts = response.data.experts
      userStore.petOwners = response.data.pet_owner
      userStore.connections = response.data.connections
      userStore.userProducts = response.data.products

      return response.data
    })
  }

  public static async login(email?: string, phone?: string): Promise<void> {
    return ApiService.post(`auth/login`, { email, phone })
  }

  public static async logout(): Promise<void> {
    const userStore = useUserStore()
    return ApiService.post<void>(`auth/logout`).finally(() => {
      userStore.logout()
      EventBus.dispatch('SIGN_OUT')
    })
  }

  public static async otpLogin(email: string, otp: string): Promise<UserInfo> {
    const userStore = useUserStore()
    return ApiService.post<ApiResponse<{ token: string }>>(`auth/otp-login`, { email, otp }).then((response) => {
      userStore.token = response.data.token
      return ApiService.get<ApiResponse<UserInfo>>(`main`).then((response) => {
        EventBus.dispatch('SIGN_IN', response.data)
        return response.data
      })
    })
  }

  public static async quickRegister(postData: QuickRegisterRequest): Promise<UserInfo> {
    const userStore = useUserStore()
    const redirectLink = userStore.redirectLink
    userStore.logout()
    userStore.redirectLink = redirectLink

    return ApiService.post('auth/quick-register', postData)
  }

  public static async register(postData: UserRegisterRequest): Promise<UserInfo> {
    // Clear user store except specific fields
    const userStore = useUserStore()
    const onboardingPets = userStore.onboardingPets
    const redirectLink = userStore.redirectLink
    const token = userStore.token
    userStore.logout()
    userStore.onboardingPets = onboardingPets
    userStore.redirectLink = redirectLink
    userStore.token = token

    // TODO: store image independently
    return ApiService.post('auth/register', postData)
  }

  public static setDeviceId(deviceId: string): void {
    const userStore = useUserStore()
    userStore.deviceId = deviceId
  }

  public static getServerVersion(): Promise<{ commit_hash: string, commit_time: string }> {
    return ApiService.get(`version`, {})
  }

  public static getDeviceId(): string {
    const userStore = useUserStore()
    return userStore.deviceId
  }

  public static requestInvestorInformation(params: InvestorRequest): Promise<void> {
    return ApiService.post(`contact/investor`, params)
  }

  public static registerDevice(): Promise<boolean | void> {
    return new Promise<boolean | void>((resolve) => {
      const userStore = useUserStore()
      if (!userStore.deviceId || !userStore.isLoggedIn()) {
        resolve();
        return;
      }
      ApiService.post('devices/register', { token: this.getDeviceId(), type: 'web' })
        .then(() => resolve())
        .catch(() => resolve())
    })
  }

  public static saveBankAccount(bankAccountName: string, bankAccountNumber: string): Promise<void> {
    const params = {
      bank_account_number: bankAccountNumber,
      bank_account_name: bankAccountName,
    }
    const userStore = useUserStore()
    return ApiService.put(`${this.url}/${userStore.user?.id}`, params)
  }

  public static removeDevice(): Promise<void> {
    EventBus.dispatch('SIGN_OUT')
    const deviceId = this.getDeviceId()
    if (deviceId) {
      return ApiService.delete(`devices/${deviceId}`, {})
    } else {
      return Promise.resolve()
    }
  }

  public static getList(queryParams: Partial<ListRequest>): Promise<PaginationModel<User>> {
    return ApiService.get<PaginationModel<User>>(this.url, queryParams)
  }

  public static getUserFrom(id: string): Promise<User> {
    return ApiService.get<ApiResponse<{ user: User }>>(`${this.url}/${id}`)
      .then((response) => response.data.user)
  }

  public static getUserShortInfo(id: string): Promise<UserShortInfo> {
    return ApiService.get<ApiResponse<{ user: UserShortInfo }>>(`${this.url}/${id}/shortinfo`)
      .then((response) => response.data.user)
  }

  public static getPetOwner(id: string): Promise<PetOwner> {
    return ApiService.get<ApiResponse<{ pet_owner: PetOwner }>>(`${this.url}/${id}/pet_owner/details`)
      .then((response) => response.data.pet_owner)
  }

  public static deleteUser(id: string): Promise<void> {
    return ApiService.delete(`${this.url}/${id}`, {})
  }

  public static hardDeleteUser(): Promise<void> {
    return ApiService.delete(`${this.url}/me/delete-account`, {})
  }

  public static saveUser(id: string, params: SaveUserRequest | User): Promise<User> {
    if (id) {
      return ApiService.put<ApiResponse<{ user: User }>>(`${this.url}/${id}`, params)
        .then((response) => response.data.user)
    } else {
      return ApiService.post<ApiResponse<{ user: User }>>(`${this.url}`, params)
        .then((response) => response.data.user)
    }
  }

  public static sendInvite(expertId: string, params: SendInviteRequest): Promise<void> {
    return ApiService.post(`${this.url}/expert/${expertId}/invite`, params)
  }

  public static submitContactForm(params: ContactFormRequest): Promise<void> {
    return ApiService.post(`main/contact`, params)
  }

  public static getTransactions(params: Partial<GetUserTransactionsRequest> = {}): Promise<PaginationModel<Transaction>> {
    return ApiService.get(`${this.url}/me/transactions`, true)
  }

  public static loadUserIntoStore(): Promise<User|undefined> {
    const userStore = useUserStore()
    if (!userStore.isLoggedIn()) {
      userStore.user = undefined
      userStore.experts = undefined
      userStore.petOwners = undefined
      userStore.connections = undefined
      userStore.userProducts = undefined
    }

    return this.getUserInfo()
      .then((response) => response?.user)
  }
}