import { defineAsyncComponent, onMounted, onUnmounted, ref } from 'vue'
import { EventBus } from '@/services/event-bus'
import { IncomingCall } from '@/models/call/IncomingCall'
import { CallViewModel } from '@/models/call/CallViewModel'

export function useCallOverlayDisplay() {
  const CallOverlay = defineAsyncComponent(() =>
    import('@/components/call/CallOverlay.vue')
  )
  const IncomingCallOverlay = defineAsyncComponent(() =>
    import('@/components/call/IncomingCallOverlay.vue')
  )

  const callViewModel = ref<CallViewModel|undefined>()
  const incomingCall = ref<IncomingCall|undefined>()
  const showCallOverlay = ref(false)
  const showIncomingCallOverlay = ref(false)

  onMounted(() => {
    EventBus.subscribe('.incomingCall', handleIncomingCall)
    EventBus.subscribe('.endCall', handleHideIncomingCallOverlay)
    EventBus.subscribe('StartCall', handleStartCall)
    EventBus.subscribe('showCallOverlay', handleShowCallOverlay)
    EventBus.subscribe('hideCallOverlay', handleHideCallOverlay)
    EventBus.subscribe('showIncomingCallOverlay', handleShowIncomingCallOverlay)
    EventBus.subscribe('hideIncomingCallOverlay', handleHideIncomingCallOverlay)
  })

  onUnmounted(() => {
    EventBus.unsubscribe('.incomingCall', handleIncomingCall)
    EventBus.unsubscribe('.endCall', handleHideIncomingCallOverlay)
    EventBus.unsubscribe('StartCall', handleStartCall)
    EventBus.unsubscribe('showCallOverlay', handleShowCallOverlay)
    EventBus.unsubscribe('hideCallOverlay', handleHideCallOverlay)
    EventBus.unsubscribe('showIncomingCallOverlay', handleShowIncomingCallOverlay)
    EventBus.unsubscribe('hideIncomingCallOverlay', handleHideIncomingCallOverlay)
  })

  const handleHideCallOverlay = () => {
    showCallOverlay.value = false
    callViewModel.value = undefined
  }

  const handleHideIncomingCallOverlay = () => {
    showIncomingCallOverlay.value = false
  }

  const handleIncomingCall = (data: IncomingCall) => {
    EventBus.dispatch('showIncomingCallOverlay', data)
  }

  const handleShowCallOverlay = (data: CallViewModel) => {
    callViewModel.value = data
    showCallOverlay.value = true
  }

  const handleShowIncomingCallOverlay = (data: IncomingCall) => {
    incomingCall.value = data
    showIncomingCallOverlay.value = true
  }

  const handleStartCall = (data: CallViewModel) => {
    EventBus.dispatch('hideIncomingCallOverlay')
    EventBus.dispatch('showCallOverlay', data)
  }

  return {
    CallOverlay,
    IncomingCallOverlay,
    callViewModel,
    incomingCall,
    showCallOverlay,
    showIncomingCallOverlay,
  }
}