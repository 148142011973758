<template>
  <div
    class="flex flex-col items-center justify-center rounded-full"
    :class="{ 'border-2 border-blue-300 border-dashed p-0.5': dashed }"
  >
    <div class="grow w-full h-full flex flex-row items-center justify-center bg-blue-400 rounded-full">
      <img v-if="imageUrl && !imageError" :src="imageUrl" class="grow h-full rounded-full" @error="imageError = true" />
      <div v-if="(!imageUrl || imageError) && imageText" class="pt-[0.166em] text-xl text-white">
        {{ imageText.substring(0, 1).toUpperCase() }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'

const imageError = ref(false)

const props = defineProps<{
  dashed?: boolean|undefined
  imageUrl?: string|undefined
  imageText?: string|undefined
}>()

watch(() => props.imageUrl, () => {
  imageError.value = false
})
</script>