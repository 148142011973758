import { defineStore } from 'pinia'
import Country from '@/models/country/Country'
import { ref } from 'vue'
import Salutation from '@/models/salutation/Salutation'
import ExpertCategory from '@/models/expert/ExpertCategory'
import PetSpecies from '@/models/pet/PetSpecies'
import VatRate from '@/models/invoice/VatRate'

export const useCacheStore = defineStore('cache', () => {
  // NOTE: Store values are serialized to JSON. Since JSON does not support Date objects, those would get deserialized back to strings.
  //       Thus, we store the timestamps as numbers and implement getters and setters.
  // state
  const cacheLanguage = ref<string>('')
  const countries = ref<Country[]>([])
  const countriesCacheTime = ref<number|undefined>(undefined)
  const currencies = ref<string[]>([])
  const currenciesCacheTime = ref<number|undefined>(undefined)
  const expertCategories = ref<ExpertCategory[]>([])
  const expertCategoriesCacheTime = ref<number|undefined>(undefined)
  const features = ref<string[]>([])
  const featuresCacheTime = ref<number|undefined>(undefined)
  const petSpecies = ref<PetSpecies[]>([])
  const petSpeciesCacheTime = ref<number|undefined>(undefined)
  const salutations = ref<Salutation[]>([])
  const salutationsCacheTime = ref<number|undefined>(undefined)
  const vatRates = ref<VatRate[]>([])
  const vatRatesCacheTime = ref<number|undefined>(undefined)

  // getters
  const getCountriesCacheTime = () => countriesCacheTime.value ? new Date(countriesCacheTime.value ?? 0) : undefined
  const getCurrenciesCacheTime = () => currenciesCacheTime.value ? new Date(currenciesCacheTime.value ?? 0) : undefined
  const getExpertCategoriesCacheTime = () => expertCategoriesCacheTime.value ? new Date(expertCategoriesCacheTime.value ?? 0) : undefined
  const getFeaturesCacheTime = () => featuresCacheTime.value ? new Date(featuresCacheTime.value ?? 0) : undefined
  const getPetSpeciesCacheTime = () => petSpeciesCacheTime.value ? new Date(petSpeciesCacheTime.value ?? 0) : undefined
  const getSalutationsCacheTime = () => salutationsCacheTime.value ? new Date(salutationsCacheTime.value ?? 0) : undefined
  const getVatRatesCacheTime = () => vatRatesCacheTime.value ? new Date(vatRatesCacheTime.value ?? 0) : undefined
  const isFeatureActive = (feature: string) => features.value.includes(feature)

  // setters
  const languageChanged = (newLanguage: string) => {
    if (newLanguage === cacheLanguage.value) return
    cacheLanguage.value = newLanguage
    countries.value = []
    currencies.value = []
    expertCategories.value = []
    petSpecies.value = []
    salutations.value = []
    vatRates.value = []
  }
  const setCountriesCacheTime = (value: Date|undefined) => countriesCacheTime.value = value?.getTime()
  const setCurrenciesCacheTime = (value: Date|undefined) => currenciesCacheTime.value = value?.getTime()
  const setExpertCategoriesCacheTime = (value: Date|undefined) => expertCategoriesCacheTime.value = value?.getTime()
  const setFeaturesCacheTime = (value: Date|undefined) => featuresCacheTime.value = value?.getTime()
  const setPetSpeciesCacheTime = (value: Date|undefined) => petSpeciesCacheTime.value = value?.getTime()
  const setSalutationsCacheTime = (value: Date|undefined) => salutationsCacheTime.value = value?.getTime()
  const setVatRatesCacheTime = (value: Date|undefined) => vatRatesCacheTime.value = value?.getTime()

  return {
    cacheLanguage,
    countries,
    countriesCacheTime,
    currencies,
    currenciesCacheTime,
    expertCategories,
    expertCategoriesCacheTime,
    features,
    featuresCacheTime,
    petSpecies,
    petSpeciesCacheTime,
    salutations,
    salutationsCacheTime,
    vatRates,
    vatRatesCacheTime,

    getCountriesCacheTime,
    getCurrenciesCacheTime,
    getExpertCategoriesCacheTime,
    getFeaturesCacheTime,
    getPetSpeciesCacheTime,
    getSalutationsCacheTime,
    getVatRatesCacheTime,
    isFeatureActive,

    languageChanged,
    setCountriesCacheTime,
    setCurrenciesCacheTime,
    setExpertCategoriesCacheTime,
    setFeaturesCacheTime,
    setPetSpeciesCacheTime,
    setSalutationsCacheTime,
    setVatRatesCacheTime,
  }
}, { persist: !import.meta.env.SSR })