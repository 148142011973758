import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: ':path*',
    name: 'admin.media.list',
    component: () => import('./List.vue'),
    meta: { header: { showBackButton: true } }
  },
]

export default routes
