import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import io from 'socket.io-client'
import ApiService from '@/services/ApiService'
import Config from '@/utils/config'
import { EventBus } from '@/services/event-bus'
import { showStickyToast } from '@/utils/toast'
import Connection from '@/models/user/Connection'

export default class WebsocketService {
  protected static status: 'initial' | 'connecting' | 'connected' = 'initial'
  protected static echo?: Echo
  private static TOAST_NAME = 'socket'

  public static connect() {
    if (this.status != 'initial') {
      this.disconnect()
    }
    this.status = 'connecting'

    // @ts-ignore-next-line
    window.io = io

    // @ts-ignore-next-line
    window.Pusher = Pusher

    try {
      this.echo = new Echo({
        broadcaster: 'pusher',
        key: Config.socketKey,
        wsHost: Config.socketHost,
        wsPort: Config.socketPort,
        wssPort: Config.socketPort,
        authEndpoint: Config.socketAuthEndpoint,
        auth: {
          headers: ApiService.getAuthHeader(),
        },
        forceTLS: Config.socketTls,
        disableStats: true,
        enabledTransports: ['ws'],
        cluster: '',
      })

      this.echo.connector.pusher.connection
        .bind('connected', () => {
          EventBus.dispatch('hideStickyToast', this.TOAST_NAME)
        })
        .bind('offline', () => {
          this.showSocketConnectError()
        })
        .bind('failed', () => {
          this.showSocketConnectError()
        })
        .bind('unavailable', () => {
          this.showSocketConnectError()
        })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Websocket initialization error', e)
    }

    this.status = 'connected'
  }

  public static disconnect() {
    this.echo?.disconnect()
    this.echo = undefined
    this.status = 'initial'
  }

  public static listenUser(userId: string) {
    if (this.echo == undefined) return
    this.echo
      .join(`user.${userId}`)
      // TODO: check whether .listenChunked is still required
      .listen('.messageReceived', (data: never) => {
        EventBus.dispatch('.messageReceived', data)
      })
      .listen('.actionReceived', (data: never) => {
        EventBus.dispatch('.actionReceived', data)
      })
      .listen('.UserUpdated', (data: never) => {
        EventBus.dispatch('.userUpdated', data)
      })
      .listen('.expertUpdated', (data: never) => {
        EventBus.dispatch('.expertUpdated', data)
      })
      .listen('.petOwnerUpdated', (data: never) => {
        EventBus.dispatch('.petOwnerUpdated', data)
      })
      .listen('.connectionCreated', (data: never) => {
        const connection = new Connection()
        Object.assign(connection, data)
        EventBus.dispatch('.connectionCreated', connection)
      })
      .listen('.connectionUpdated', (data: never) => {
        const connection = new Connection()
        Object.assign(connection, data)
        EventBus.dispatch('.connectionUpdated', connection)
      })
      .listen('.featureStatusChanged', (data: never) => {
        EventBus.dispatch('.featureStatusChanged', data)
      })
      .listen('.subscribed', (data: never) => {
        EventBus.dispatch('.subscribed', data)
      })
      .listen('.unsubscribed', (data: never) => {
        EventBus.dispatch('.unsubscribed', data)
      })
      .listen('.petCreated', (data: never) => {
        EventBus.dispatch('.petCreated', data)
      })
      .listen('.petUpdated', (data: never) => {
        EventBus.dispatch('.petUpdated', data)
      })
      .listen('.petDeleted', (data: never) => {
        EventBus.dispatch('.petDeleted', data)
      })
      .listen('.incomingCall', (data: never) => {
        EventBus.dispatch('.incomingCall', data)
      })
      .listen('.joinCall', (data: never) => {
        EventBus.dispatch('.joinCall', data)
      })
      .listen('.rejectedCall', (data: never) => {
        EventBus.dispatch('.rejectedCall', data)
      })
      .listen('.endCall', (data: never) => {
        EventBus.dispatch('.endCall', data)
      })
      .listen('.invoiceStatusChanged', (data: never) => {
        EventBus.dispatch('.invoiceStatusChanged', data)
      })
      .error((err: string) => {
        // eslint-disable-next-line no-console
        console.log('Websocket error', err)
        this.showSocketConnectError()
      })
  }

  public static stopListenUser(userId: string) {
    if (this.echo == undefined) return
    this.echo.leave(`App.Models.User.${userId}`)
  }

  public static getStatus(): string {
    return this.status
  }

  private static showSocketConnectError(): void {
    showStickyToast(this.TOAST_NAME, 'error_messages.socket_connect', 'danger')
  }
}
