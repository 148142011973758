import { computed, defineComponent, h, nextTick, PropType, ref, watch } from 'vue'

import LogoIcon from '@/assets/images/app_logo.svg'
import LogoSmallIcon from '@/assets/images/app_logo_small.svg'
import LogoWhiteIcon from '@/assets/images/white_logo.svg'
import LogoWhiteSmallIcon from '@/assets/images/white_logo_small.svg'

export enum LogoSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  DEFAULT = 'default',
}

const SizeMap: Record<LogoSize, { width: number, height: number }> = {
  [LogoSize.SMALL]: {
    width: 72,
    height: 36,
  },
  [LogoSize.MEDIUM]: {
    width: 91,
    height: 42,
  },
  [LogoSize.DEFAULT]: {
    width: 114,
    height: 53,
  },
}

export const Logo = defineComponent({
  name: 'Logo',
  props: {
    blueTheme: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<LogoSize>,
      default: LogoSize.DEFAULT,
    },
  },
  setup(props) {
    const wrapper = ref<HTMLElement | undefined>(undefined)

    const icon = computed(() => props.blueTheme
      ? (props.size === LogoSize.SMALL ? LogoWhiteSmallIcon : LogoWhiteIcon)
      : (props.size === LogoSize.SMALL ? LogoSmallIcon : LogoIcon))

    watch([() => props.size, icon], () => {
      nextTick(() => {
        const svg = wrapper.value?.firstChild as HTMLElement | undefined
        svg?.setAttribute('width', SizeMap[props.size].width.toString())
        svg?.setAttribute('height', SizeMap[props.size].height.toString())
      })
    }, { immediate: true })

    return () => h('span', { ref: wrapper }, h(icon.value))
  },
})
