<template>
  <IconButton class="m-1" @click="back" :icon="BackIcon" :icon-size="18" light :size="ButtonSize.SMALL" :variant="ButtonVariant.ATTENTION">
    {{ t('common.back') }}
  </IconButton><br>
</template>

<script lang="ts" setup>
import { ButtonSize, ButtonVariant, IconButton } from '@/components/ui';
import BackIcon from '@/assets/icons/back.svg?raw'
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const { t } = useI18n()
const router = useRouter()

const props = withDefaults(defineProps<{
  toUrl: string|undefined
}>(), {
  toUrl: undefined
})

const back = () => {
  if (props.toUrl) {
    void router.push(props.toUrl)
    return
  }

  router.back()
}
</script>