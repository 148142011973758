import SavePetOwnerRequest from '@/models/requests/SavePetOwnerRequest'
import ListRequest from '@/models/requests/ListRequest'
import PaginationModel from '@/models/responses/PaginationModel'
import PetOwner from '@/models/pet-owner/PetOwner'
import ApiService from '@/services/ApiService'
import ExpertPetOwner from '@/models/expert/ExpertPetOwner'
import ApiResponse from '@/models/responses/ApiResponse'
import Connection from '@/models/user/Connection'
import PetOwnerImport from '@/models/pet-owner/PetOwnerImport'
import PetOwnerInvite from '@/models/pet-owner/PetOwnerInvite'

export default class PetOwnerService {
  private static url = 'pet_owner'

  public static connectToPetOwner(id: string): Promise<Connection> {
    return ApiService.post<ApiResponse<{ connection: Connection }>>(`${this.url}/${id}/connect`, {})
      .then((response) => response.data.connection)
  }

  public static deletePetOwner(id: string): Promise<void> {
    return ApiService.delete(`${this.url}/${id}`, {})
  }

  public static disconnectExpert(id: string): Promise<void> {
    return ApiService.post(`${this.url}/${id}/disconnect`, {})
  }

  public static disconnectPetOwner(id: string): Promise<void> {
    return ApiService.post(`${this.url}/${id}/disconnect`, {})
  }

  public static getExpertPetOwnerList(params: Partial<ListRequest> = {}): Promise<ExpertPetOwner[]> {
    return ApiService.get<ApiResponse<ExpertPetOwner[]>>(`${this.url}/expert`, params)
      .then((response) => response.data)
  }

  public static getList(params: Partial<ListRequest> = {}): Promise<PaginationModel<PetOwner>> {
    return ApiService.get<PaginationModel<PetOwner>>(`${this.url}`, params)
  }

  public static getPetOwner(id: string): Promise<PetOwner> {
    return ApiService.get<ApiResponse<{ pet_owner: PetOwner }>>(`${this.url}/${id}`)
      .then((response) => response.data.pet_owner)
  }

  public static parseFile(fileName: string, hasHeader: boolean, mapping: string[]): Promise<PetOwnerInvite[]> {
    const params = {
      filename: fileName,
      has_header: hasHeader,
      mapping,
    }
    return ApiService.post<ApiResponse<{ recipients: PetOwnerInvite[] }>>(`${this.url}/import/parse`, params)
      .then((response) => response.data.recipients)
  }

  public static prepareFileUpload(file: File): Promise<PetOwnerImport> {
    return ApiService.post<ApiResponse<PetOwnerImport>>(`${this.url}/import/prepare`, { file })
      .then((response) => response.data)
  }

  public static savePetOwner(id: string, params: Partial<SavePetOwnerRequest>): Promise<void> {
    if (id) {
      return ApiService.put(`${this.url}/${id}`, params)
    } else {
      return ApiService.post(`${this.url}`, params)
    }
  }
}
