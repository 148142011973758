import { RouteRecordRaw } from 'vue-router'
import ExpertRoutes from './expert/routes'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'pet-expert',
    children: ExpertRoutes,
  },
  {
    path: 'register',
    name: 'onboarding-register',
    component: () => import('./Register.vue'),
    meta: { header: { hideButtons: true, showLogin: true } }
  },
  {
    path: 'select',
    name: 'onboarding-select',
    component: () => import('./Select.vue'),
    meta: { header: { hideButtons: true, showLogin: true } }
  },
]

export default routes
