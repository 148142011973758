import Config from '@/utils/config'
import { useI18n } from 'vue-i18n'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { EventBus } from '@/services/event-bus'
import UserService from '@/services/UserService'
import { useUserStore } from '@/stores/UserStore'
import UserInfo from '@/models/user/UserInfo'

export function useOneSignal() {
  const oneSignalId = ref<string | undefined>()
  const userStore = useUserStore()
  const { t } = useI18n()

  onMounted(() => {
    init()
  })

  onUnmounted(() => {
    EventBus.unsubscribe('SIGN_IN', handleSignIn)
    EventBus.unsubscribe('SIGN_OUT', handleSignOut)
  })

  const addScript = (fileSrc: string, callback: () => void) => {
    const head = document.getElementsByTagName('head')[0]
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.defer = true
    script.onload = callback
    script.src = fileSrc
    head.appendChild(script)
  }

  const checkIfSubscribed = () => {
    const isEnabled = oneSignalInstance.value.Notifications.permission as boolean
    if (isEnabled) {
      updateDeviceId()
    } else {
      oneSignalInstance.value.Slidedown.promptPush()
    }
  }

  const handleSignIn = (userInfo: UserInfo) => {
    oneSignalInstance.value.login(userInfo.user.id)
  }

  const handleSignOut = () => {
    oneSignalInstance.value.logout()
  }

  const init = async () => {
    if (!Config.oneSignalAppId || Config.environment == 'e2e') {
      return
    }
    if (typeof window === 'undefined') {
      return
    }
    // @ts-ignore
    const OneSignal = window['OneSignal']
    if (OneSignal) {
      return
    }

    const settings = await oneSignalSettings()
    // @ts-ignore
    window['OneSignalDeferred'] = window['OneSignalDeferred'] || []
    // @ts-ignore
    window['OneSignalDeferred'].push(function(OneSignal) {
      OneSignal.init(settings)
    })

    addScript('https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js', () => {
      const interval = setInterval(() => {
        // @ts-ignore
        if (!window['OneSignal']) {
          return
        }
        clearInterval(interval)

        oneSignalInstance.value.Notifications.addEventListener('permissionChange', (isSubscribed: boolean) => {
          if (isSubscribed) updateDeviceId()
        })

        initOneSignal()

        EventBus.subscribe('SIGN_IN', handleSignIn)
        EventBus.subscribe('SIGN_OUT', handleSignOut)
      }, 1000)
    })
  }

  const initOneSignal = async () => {
    if (userStore.isLoggedIn()) {
      setTimeout(() => {
        checkIfSubscribed()
      }, 1000)
    }
  }

  const oneSignalInstance = computed(() => {
    // @ts-ignore
    return window['OneSignal']
  })

  const oneSignalSettings = async () => {
    const actionMessage = t('onesignal.confirmation_text')
    const acceptButtonText = t('onesignal.ok_text')
    const cancelButtonText = t('onesignal.no_thanks_text')

    return {
      appId: Config.oneSignalAppId,
      allowLocalhostAsSecureOrigin: true,
      promptOptions: {
        slidedown: {
          prompts: [
            {
              type: 'push', // current types are "push" & "category"
              autoPrompt: false,
              text: {
                /* limited to 90 characters */
                actionMessage,
                /* acceptButton limited to 15 characters */
                acceptButton: acceptButtonText,
                /* cancelButton limited to 15 characters */
                cancelButton: cancelButtonText,
              },
            },
          ],
        },
      },
    }
  }

  const updateDeviceId = () => {
    let retries = 0
    const interval = setInterval(() => {
      if (!oneSignalInstance.value.User?.PushSubscription?.id) {
        retries++
        if (retries > 10) {
          clearInterval(interval)
        }
        return
      }
      clearInterval(interval)

      const userId = oneSignalInstance.value.User.PushSubscription.id as string;
      if (userId) {
        oneSignalId.value = userId
        UserService.setDeviceId(userId)
        UserService.registerDevice().catch(() => {})
      }
    })
  }

  return {}
}
