import Config from '@/utils/config'

export function initOneSignal() {
  if (!Config.oneSignalAppId) return

  try {
    document.getElementsByTagName('html')[0].classList.remove('loading')
    if ('serviceWorker' in navigator) {
      // Register a service worker hosted at the root of the
      // site using a more restrictive scope.
      navigator.serviceWorker.register('/OneSignalSDKWorker.js').then(
        function (registration) {
          // eslint-disable-next-line no-console
          console.log('Service worker registration succeeded:', registration)
        },
        function (error) {
          // eslint-disable-next-line no-console
          console.log('Service worker registration failed:', error)
        }
      )
    } else {
      // eslint-disable-next-line no-console
      console.log('Service workers are not supported.')
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Service worker registration failed:', error)
  }
}
