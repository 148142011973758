export type ConnectionStatus = 'blocked' | 'connected' | 'disconnected' | 'invited'

export default class Connection {
  id: string
  user1_id: string
  expert1_id: string
  user2_id: string
  expert2_id: string
  status: ConnectionStatus
  invited_by: number // 1 = user1 / expert1 invited; 2 = user2 / expert2 invited

  isInviting(userId: string|undefined, expertId: string|undefined): boolean {
    if (this.invited_by == 1) {
      if (expertId && expertId == this.expert1_id) return true
      if (userId && userId == this.user1_id) return true
    } else if (this.invited_by == 2) {
      if (expertId && expertId == this.expert2_id) return true
      if (userId && userId == this.user2_id) return true
    }
    return false
  }
}
